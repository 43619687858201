import React from 'react';
import ReactDOM from 'react-dom';
import InstaBreakSpace from './InstaBreakSpace';

ReactDOM.render(
  <React.StrictMode>
    <InstaBreakSpace />
  </React.StrictMode>,
  document.getElementById('instabreakspace')
);


import React, {useState} from 'react';

const Box = (props) => {
	
	const [currentMode, setCurrentMode] = useState('normal');
	const [currentState, setCurrentState] = useState('ready');
	const [boxContent, setBoxContent] = useState('');
	const [boxContentLength, setBoxContentLength] = useState(0);
	const [btnGenerate, setBtnGenerate] = useState(false);
	var boxAnimateTimout;	
	
	const msgs = {
		ready: "",
		done: "Done! Now paste it to your Instagram",
		error: "Sorry, an error occurred",
		typing: "Keep going...",
		converting: "Converting..."
	};
	
	// Show Msg Box
	const msgBox = () => {
		if(currentState !== 'ready' && currentState !== 'typing'){
			var txt;
			switch(currentState){
				case 'typing': txt = msgs.typing; break;
				case 'converting': txt = msgs.converting; break;
				case 'done': txt = msgs.done; break;
				case 'error': txt = msgs.error; break;
				default: txt = ''; break;
			}
			return <div className={currentState}>{txt}</div>
		}
	}
	
	// Count chars
	const showNumChars = () => {
		return <span>{boxContentLength}/∞</span>
	}
	
	// Highlight text 
	const textHighlight = () => {
		const theTextarea = document.getElementById('erd-caption-field');
		theTextarea.focus();
		theTextarea.select();
	}
	
	// Convert break spaces
	const textConvert = (a) => {
		a = a.replace(/\u2800/g, '');
		a = a.replace(/\n/g, "\u2800\n");
		setBoxContentLength(a.length);
		return a;
	}
	
	// Kalo difocus, highlight
	const textFocus = () => {
		if(currentState === 'done'){
			textHighlight();
		}
	}
	
	// When text typed
	const textChange = (e) => {
		setBoxContent(e.target.value);
		setBoxContentLength(e.target.value.length);
		if(e.target.value === ''){
			setBtnGenerate(false);
			setCurrentState('ready');
		}else{
			setBtnGenerate(true);
			setCurrentState('typing');
		}
	}
	
	// On blur 
	const textBlur = (e) => {
		if(currentMode === 'beast' && e.target.value !== ''){
			textProcessBeast();
		}
	}
	
	// Proses Convert 
	const textProcessNormal = () => {
		//clearTimeout(boxMsgTimeout);
		clearTimeout(boxAnimateTimout);
			setCurrentState('converting');
			setBtnGenerate(false);
			setBoxContent(textConvert(boxContent));
		boxAnimateTimout = setTimeout(() => {
			textHighlight();textCopy();
			setCurrentState('done');
		}, 1000);
	}
	
	const textProcessBeast = () => {
		clearTimeout(boxAnimateTimout);
		setBoxContent(textConvert(boxContent));
		boxAnimateTimout = setTimeout(() => {
			textHighlight();textCopy();
			setCurrentState('done');
		}, 1);
	}
	
	// Copy text
	const textCopy = () => {
		textHighlight();
		if(false === document.execCommand('copy')){
			navigator.clipboard.writeText(boxContent).then();
		}
	}
	
	// Clear text
	const textClear = () => {
		clearTimeout(boxAnimateTimout);
		boxAnimateTimout = setTimeout(() => {
			setBoxContent('');
			setBoxContentLength(0);
			setBtnGenerate(false);
			setCurrentState('ready');
		}, 1);
	}
	
	// Show Reset Button 
	const showResetButton = () => {
		const buttonClass = (boxContent === '') ? 'hidden' : '';
		return <span id="erd-reset-content" className={buttonClass} onClick={textClear}>&#10008; Reset</span>
	}
	
	// Show Copy Button 
	const showCopyButton = () => {
		const buttonClass = (currentState !== 'done') ? 'hidden' : '';
		return <span id="erd-copy-content" className={buttonClass} data-clipboard-target="#erd-caption-field" onClick={textCopy}>&#10064; Copy</span>
	}
	
	// Show generate button
	const buttonGenerate = () => {
		var btnClass;
		if(!btnGenerate || boxContent === '' || currentMode === 'beast'){
			btnClass = 'hidden';
		}
		return <button type="button" id="erd-get-spacer" className={btnClass} onClick={textProcessNormal}>GENERATE</button>;
	}
	
	const switchMode = (e) => {
		if(e.target.checked){
			setCurrentMode('beast');
		}else{
			setCurrentMode('normal');
		}
		
	}
	
	// Textarea 
	const boxTextarea = () => {
		return <textarea id="erd-caption-field" spellCheck="false" placeholder="Insert your captions/bio here..." onChange={(e) => textChange(e)} onKeyUp={(e) => textChange(e)} onFocus={textFocus} onBlur={(e) => textBlur(e)} value={boxContent}></textarea>
	}
	
	var convertClass;
	if(currentMode === 'normal' && currentState === 'converting'){
		convertClass = 'converting';
	}
	
	return (
<React.Fragment>
<div id="erd-notice-bar">
	{msgBox()}
</div>


<div id="erd-app-wrapper" className={convertClass}>
{boxTextarea()}
</div>

<div id="erd-buttons-adv">
<div>{showResetButton()}</div>
<div>{showNumChars()}</div>
<div>{showCopyButton()}</div>
</div>

{buttonGenerate()}

<div className="erd-beast-mode-wrapper">
<input type="checkbox" value="beast" id="erd-beast-mode-check" onChange={switchMode} />
<label id="erd-beast-mode" htmlFor="erd-beast-mode-check"> </label>
<div className="erd-mode-tips">
<div className={currentMode==='normal' ? 'erd-tip-normal':''}><strong>Normal Mode</strong>: You need to click Generate button to convert text.</div>
<div className={currentMode==='beast' ? 'erd-tip-beast':''}><strong>Beast Mode</strong>: Your text is automatically converted while typing or when pasted into the text box without clicking the button. Useful if you&#39;re in hurry or have several (feed) captions. Enjoy!</div>
</div>
</div>

</React.Fragment>
	);

}

export default Box;
import { useEffect } from 'react';
const EmbedScript = conf => {
	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		
		if(conf.url){
			script.src = conf.url;
		}
		if(conf.theScript){
			script.innerHTML = conf.theScript;
		}
		
		if(conf.location === 'body'){
			document.body.appendChild(script);
		}else{
			document.head.appendChild(script);
		}
		
		const remScript = () => {
			if(conf.location === 'body'){
				document.body.removeChild(script);
			}else{
				document.head.removeChild(script);
			}
		}

		return () => {
			remScript();
		}
	}, [conf]);
};

export default EmbedScript;
import React from 'react';

import './index.css';
import Header from './Header.js';
import Box from './Box.js';
import EmbedScript from './EmbedScript.js';
import Footer from './Footer.js';

const InstaBreakSpace = () => {
	
	const gtmLinked = {
		url: 'https://www.googletagmanager.com/gtag/js?id=G-NR073BS7NT',
		location: 'head',
		theScript: false
	};
	const gtmInner = {
		url: false,
		location: 'head',
		theScript: 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-NR073BS7NT\');'
	};
	
	EmbedScript(gtmLinked);
	EmbedScript(gtmInner);
	
	return (
<div className="erd-site-wrapper">
	<Header> </Header>
	
<section id="erd-main-app"><div className="erd-container"><div id="erd-playground">
<Box />
</div></div></section>
<Footer />
</div>
	);
}

export default InstaBreakSpace;

const Footer = () => {
	
	var nowYear = false;
	const fullDate = new Date();
	const currentYear = fullDate.getFullYear();
	
	if(currentYear > 2021){
		nowYear = ' - '+currentYear;
	}
	
	const fullDomain = () => {
		return window.location.protocol + '//' + window.location.host;
	}
	
	return(
<footer id="erd-footer"><div className="erd-container">
<div className="erd-footer-credits">&copy; 2021{nowYear} <a href={fullDomain()}>InstaBreak.space</a> by <a href="https://eirudo.com/" target="_blank">Eirudo</a></div>
</div></footer>
	);
}

export default Footer;